import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { uploadFileItem } from './base'

interface saveReq {
  id?: string
  staff_id: string
  patrol_time: string
  project_id: string
  explain: string
  pics?: Array<uploadFileItem> // 图片
}
export type saveProject = saveReq //导出请求保存的数据结构

// 项目完结申请 添加接口
export function ProgressSave(data: saveReq) {
  const url = '/api/construction_progress/save'
  return cateringRequest.post(url, data)
}
